import firebase from "../lib/firebase";
import { v4 as UUID } from "uuid";
import Dexie from 'dexie';

const db = new Dexie('armadillo');
db.version(1).stores({
    codes: `id`, //has calendar rules
    devices: `id`,
    users: `id`,
    groups: `id`,
    events: `id`,
    organizations: `id`
});

export default function (type) {
    let d = db[type];
    let lastSync = 0;
    return {
        chkDate(item) {
            if (!item.ctime || isNaN(item.ctime)) {
                item.ctime = new Date();
            }
            if (!item.mtime || isNaN(item.mtime)) {
                item.mtime = new Date();
            }
        },
        async _sync(deviceID) {
            let msgOptions = deviceID ? { deviceID: deviceID } : {};
            let msg = firebase.functions().httpsCallable(type + "_list");
            let fire = await msg(msgOptions);
            let local = await d.toArray();
            let fireKeys = {};
            let localKeys = {};

            fire.data.map(item => fireKeys[item.id] = Math.floor(Date.parse(item.mtime) * .001));
            local.map(item => localKeys[item.id] = Math.floor(Date.parse(item.mtime) * .001));

            for (let item of fire.data) {
                if (!localKeys[item.id]) {
                    await d.add(item);
                } else {
                    let a = Math.floor(Date.parse(item.mtime) * .001);
                    if (a > localKeys[item.id]) {
                        d.put(item);
                    }
                }
            }

            for (let item of local) {
                if (!fireKeys[item.id]) {
                    this.chkDate(item);
                    let msg = firebase.functions().httpsCallable(type + "_create");
                    msg({ data: item }).then((result) => {
                        if (result.data.error == 'already exists') {
                            d.delete(item.id);
                        }
                    });
                } else {
                    let a = Math.floor(Date.parse(item.mtime) * .001);
                    if (a > fireKeys[item.id]) {
                        this.chkDate(item);
                        let msg = firebase.functions().httpsCallable(type + "_update");
                        msg({ id: item.id, updated: item });
                    }
                }
            }

        },
        async get(deviceID) {
            if (lastSync < Date.now()) {
                try {
                    await this._sync(deviceID);
                } catch (err) {
                    if (err.code == 'unauthenticated') {
                        throw err;
                    }
                }
            }
            return await d.toArray();
        },
        async add(item) {
            if (!item) {
                return;
            }
            console.log('adding item');
            item.id = UUID();
            this.chkDate(item);
            let msg = firebase.functions().httpsCallable(type + "_create");
            msg({ data: item });
            return await d.add(item);
        },
        async update(item) {
            if (!item) {
                return;
            }
            item.mtime = new Date();
            let msg = firebase.functions().httpsCallable(type + "_update");
            msg({ id: item.id, updated: item });
            return await d.put(item);
        },
        async delete(item) {
            if (!item) {
                return;
            }
            let msg = firebase.functions().httpsCallable(type + "_del");
            msg({ id: item.id });
            return await d.delete(item.id);
        }
    }
}