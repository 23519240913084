let me = null;

export default class BLE {

    #b = undefined;

    constructor() {
        if (me) {
            return me;
        }

        me = this;
        if (window.ble) {
            this.#b = window.ble;
            console.log('we have a ble device');
        } else {
            //Mock a ble device with fake data
            this.#b = new BLEMock();
        }
    }

    scan(uuids, seconds, onDiscover, onError) {
        console.log("scanning");
        this.#b.scan(uuids, seconds, onDiscover, onError);
    }

    stopScan() {
        return new Promise((resolve, reject) => {
            let timer = setTimeout(() => {
                reject("timeout waiting for scan to stop");
            }, 3000);
            this.#b.stopScan(() => {
                clearTimeout(timer);
                resolve();
            }, reject);
        })
    }

    connect(device_id, connectedCallback, disconnectedCallback) {
        this.#b.connect(device_id, connectedCallback, disconnectedCallback);
    }

    startNotification(device_id, service_uuid, characteristic_uuid, success, error) {
        this.#b.startNotification(device_id, service_uuid, characteristic_uuid, success, error);
    }

    stopNotification(device_id, service_uuid, characteristic_uuid) {
        this.#b.stopNotification(device_id, service_uuid, characteristic_uuid);
    }

    disconnect(id) {
        this.#b.disconnect(id);
    }

    read(device_id, service_uuid, characteristic_uuid) {
        return new Promise((resolve, reject) => {
            this.#b.read(device_id, service_uuid, characteristic_uuid, resolve, reject);
        });
    }

    async write(device_id, service_uuid, characteristic_uuid, data) {
        return new Promise((resolve, reject) => {
            this.#b.write(device_id, service_uuid, characteristic_uuid, data.buffer, resolve, reject);
        });
    }
}

class BLEMock {
    /*eslint no-unused-vars: "off" */
    read(device_id, service_uuid, characteristic_uuid) {
        return new Promise().resolve("cool beaners");
    }

    startNotification(device_id, service_uuid, characteristic_uuid, success, error){
        success()
    }

    stopNotification(device_id, service_uuid, characteristic_uuid){

    }

    disconnect(id){

    }

    async write(device_id, service_uuid, characteristic_uuid, data){

    }

    /*eslint no-unused-vars: "off" */
    scan(uuids, listSize, onDiscover, onError) {
        return new Promise((resolve, reject)=>{
            let cnt = 0;
            let interval = setInterval(() => {
                if (cnt++ > 8) {
                    clearInterval(interval);
                }
                onDiscover({
                    icon: "folder",
                    "name": "TI SensorTag " + cnt,
                    "id": "BD922605-1B07-4D55-8D09-B66653E51BBA"+cnt,
                    "rssi": -79,
                    "advertising": [2, 1, 6, 3, 3, 15, 24, 8, 9, 66, 97, 116, 116, 101, 114, 121]/* ArrayBuffer or map */
                })
            }, 300);
        });
    }
    async stopScan(){}

    /*eslint no-unused-vars: off */
    connect(id, connectedCallback, disconnectedCallback) {
        setTimeout(() => {
            connectedCallback({
                "name": "Battery Demo",
                "id": "20:FF:D0:FF:D1:C0",
                "advertising": [2, 1, 6, 3, 3, 15, 24, 8, 9, 66, 97, 116, 116, 101, 114, 121],
                "rssi": -55,
                "services": [
                    "1800",
                    "1801",
                    "180f"
                ],
                "characteristics": [
                    {
                        "service": "1800",
                        "characteristic": "2a00",
                        "properties": [
                            "Read"
                        ]
                    },
                    {
                        "service": "1800",
                        "characteristic": "2a01",
                        "properties": [
                            "Read"
                        ]
                    },
                    {
                        "service": "1801",
                        "characteristic": "2a05",
                        "properties": [
                            "Read"
                        ]
                    },
                    {
                        "service": "180f",
                        "characteristic": "2a19",
                        "properties": [
                            "Read"
                        ],
                        "descriptors": [
                            {
                                "uuid": "2901"
                            },
                            {
                                "uuid": "2904"
                            }
                        ]
                    }
                ]
            });
        }, 100)
    }
}