<template>
  <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden">
    <div style="flex-grow: 1; display: flex; flex-direction: column; overflow-y: auto; overflow-x: hidden; padding-bottom: 7em">
      <v-btn absolute dark fab right bottom color="green darken-2" style="bottom: 3%" @click="create()">
        <v-icon>fa-plus</v-icon>
      </v-btn>
      <div>
        <v-row dense>
          <v-col v-for="(device, i) in devices" :key="i" cols="12">
            <v-card tile flat :color="device.ui.color" :dark="device.ui.dark" style="width: 100%" class="ml-0 pt-4 pb-4" @click="select(i)">
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-avatar style="border-radius: 4px 0 0 4px !important" size="100" tile>
                  <v-img :src="device.ui.src"></v-img>
                </v-avatar>
                <div style="flex-grow: 1">
                  <v-card-title class="headline d-inline-block text-truncate">{{ device.ui.title }}</v-card-title>
                  <v-card-subtitle>{{ device.ui.description }}</v-card-subtitle>
                </div>
                <div class="pr-4">
                  <v-icon x-small>fa-chevron-right</v-icon>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Device",
  created() {
    this.list();
  },
  data() {
    return {
      devices: []
    };
  },
  methods: {
    select(index) {
      this.$store.device = this.devices[index];
      this.$router.push({ name: "Unlock" });
    },
    create() {
      this.$router.push({ name: "Scan" });
    },
    async list() {
      try {
        let result = await this.$store.db.devices.get();
        if (result.length == 0) {
          this.$router.push({ name: "Scan" });
          return;
        }
        result.sort((a, b) => a.ui.title.localeCompare(b.ui.title));
        this.devices = result;
      } catch (e) {
        if (e.code == "unauthenticated") {
          console.log("unauthenticated");
          this.$router.push({ name: "Login" });
        }
        return;
      }
    }
  }
};
</script>