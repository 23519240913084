<template>
  <v-app id="inspire">
    <v-snackbar v-model="snackbar" timeout="5000" elevation="0" color="#00000000">
      <v-alert
        elevation="10"
        class="ma-0"
        :color="snackColor"
        dark
        border="top"
        :icon="snackIcon"
        transition="scale-transition"
        @click="snackbar = false"
      >
        {{ text }}
      </v-alert>
    </v-snackbar>
    <div style="display: flex; flex-grow: 1; overflow: hidden; background-color: #e8e8e8">
      <div class="d-none d-md-block" style="width: 250px">
        <v-sheet class="pa-4">
          <img style="max-height: 30px; marign: 1em" src="/app/img/logo.png" />
        </v-sheet>
        <v-divider></v-divider>
        <v-btn tile elevation="0" style="width: 100%; justify-content: left; height: 60px" to="/organizations">
          <v-icon style="width: 20px" dense>fa fa-sitemap</v-icon>
          <div style="width: 20px"></div>
          <span>Organizations</span>
        </v-btn>
        <v-btn tile elevation="0" style="width: 100%; justify-content: left; height: 60px" to="/groups">
          <v-icon style="width: 20px" dense>fa fa-user-friends</v-icon>
          <div style="width: 20px"></div>
          <span>Groups</span>
        </v-btn>
        <v-divider></v-divider>
        <v-btn
          tile
          elevation="0"
          style="width: 100%; justify-content: left; height: 60px"
          :to="item.route"
          v-for="item in items"
          :key="item.title"
          :disabled="item.enabled ? !item.enabled : $store.device == undefined"
        >
          <v-icon style="width: 20px" dense>{{ item.icon }}</v-icon>
          <div style="width: 20px"></div>
          <span>{{ item.title }}</span>
        </v-btn>
        <v-footer app class="d-none d-md-block pa-1 pr-5 text-center" style="width: 250px">
          <span>Armadillo Systems &copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
      </div>

      <v-main class="pa-0" style="overflow: hidden; flex-grow: 1; height: 100%">
        <!-- <div style="padding-top: 56px" class="d-none d-md-block"></div> -->
        <router-view v-on:hideMenu="hideMenu" v-on:showMenu="showMenu" v-on:alert="showAlert"></router-view>
      </v-main>
    </div>

    <v-bottom-navigation v-show="menuVisible" shift style="color: #784f2b; border-top: 1px solid #ccc" dense color="red darken-2" class="d-md-none">
      <v-btn :to="item.route" v-for="item in items" :key="item.title" :disabled="item.enabled ? !item.enabled : $store.device == undefined">
        <span>{{ item.title }}</span>
        <div class="btn-spacer"></div>
        <v-icon dense>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import store from "./store";

export default {
  name: "App",
  data: () => ({
    nav: store.navigation,
    menuVisible: true,
    snackbar: false,
    snackColor: "green",
    snackIcon: "fa-check-circle",
    text: "ok",
    items: [
      { title: "Device", icon: "fa-atom", route: "/", enabled: true },
      { title: "Unlock", icon: "fa-unlock-alt", route: "/unlock" },
      { title: "People", icon: "fa-user-friends", route: "/people" },
      { title: "Calendar", icon: "fa-calendar-alt", route: "/calendar" },
      { title: "Log", icon: "fa-file", route: "/log" },
      { title: "Settings", icon: "fa-cogs", route: "/settings" }
    ]
  }),
  watch: {
    $route: function () {
      this.menuVisible = true;
    }
  },
  methods: {
    showAlert(txt, type) {
      console.log("show alert");
      this.text = txt;
      switch (type) {
        case "error":
          this.snackIcon = "fa-exclamation-circle";
          this.snackColor = "red darken-3";
          break;
        //Success is default style
        default:
          this.snackIcon = "fa-check-circle";
          this.snackColor = "green";
      }
      this.snackbar = true;
    },
    hideMenu() {
      this.menuVisible = false;
    },
    showMenu() {
      this.menuVisible = true;
    }
  }
};
</script>

<style>
body,
html,
.v-application {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fffdfc;
  overflow: hidden;
}
.btn-spacer {
  height: 6px;
}

.v-btn > .v-btn__content .v-icon {
  color: #784f2b;
}

.v-snack__wrapper {
  position: absolute !important;
  top: 0px !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.v-main__wrap {
  position: inherit !important;
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 70px !important;
}
</style>
