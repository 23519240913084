import database from './database';
import BLE from "../lib/ble";

export default {
  user: null,
  db: {
    codes: database("codes"),
    devices: database("devices"),
    users: database("users"),
    groups: database("groups"),
    events: database("events"),
    organizations: database("organizations"),
  },
  deviceID: 0,
  device: undefined,
  router: null,
  connection: null,
  connected: false,
  listeners: [],
  navigation: {
    visible: true
  },
  cnf: {

  },
  async connect() {
    if (!this.device || !this.device.ble_id || this.connected) {
      return;
    }
    console.log("setting up comms for device: ", this.device.ble_id);

    let me = this;
    let comm = new Communication(this.device.ble_id);
    comm.dataCallback = this.dataCallback.bind(this);
    comm.disconnectCallback = this.disconnected.bind(this);
    this.connection = comm;
    this.connected = false;

    return new Promise((resolve, reject) => {
      let retries = 0;
      let retryCnt = 0;
      comm.connect(() => {
        me.connected = true;
      });
      let interval = setInterval(() => {
        if (me.connected) {
          console.log("connected");
          clearInterval(interval);
          resolve();
        }
        if (retryCnt++ > 100) {
          if (retries++ > 2) {
            clearInterval(interval);
            console.log("failed to connect");
            reject("failed to connect");
          }
          retryCnt = 0;
          comm.connect(() => {
            me.connected = true;
          });
        }
      }, 100);
    })
  },
  addListener(listener) {
    this.listeners.push(listener);
  },
  removeListener(listener) {
    let i = this.listeners.indexOf(listener);
    this.listeners.splice(i, 1);
  },
  dataCallback(data) {
    console.log("sample callback data", data);
    for (let listen of this.listeners) {
      listen(data);
    }
  },
  disconnected() {
    this.connected = false;
    console.log('disconnected');
  },
}


class Communication {
  svcUUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
  writeUUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
  notifyUUID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";

  id = undefined;
  device = undefined;
  ble = undefined;
  dataCallback = undefined;
  disconnectCallback = undefined;

  constructor(id) {
    this.id = id;
    this.write = this.write.bind(this);
    this.notify = this.notify.bind(this);
  }

  connect(callback) {
    this.disconnectCallback = this.disconnectCallback.bind(this);
    console.log('disconnect callback set');
    this.ble = new BLE();
    console.log('new ble connecting to', this.id);
    this.ble.connect(
      this.id,
      (device) => {
        console.log('device', device);
        this.device = device;
        this.ble.startNotification(
          this.id,
          this.svcUUID,
          this.notifyUUID,
          this.notify,
          console.log
        );
        console.log('calling callback');
        callback();
      },
      this.disconnectCallback
    );
  }

  notify(data) {
    data = this._parse(data);
    if (this.dataCallback) {
      this.dataCallback(data);
    }
    // console.log(data);
  }

  disconnect() {
    if (this.device) {
      this.ble.disconnect(this.id);
    }
  }

  write(data) {
    console.log('ble write')
    let enc = new TextEncoder();
    console.log('ble encoder ready')
    return this.ble.write(
      this.id,
      this.svcUUID,
      this.writeUUID,
      enc.encode(data)
    );
  }

  _parse(data) {
    data = String.fromCharCode.apply(null, new Uint8Array(data));
    return data;
  }

  disconnected() {
    this.disconnectCallback();
  }
}
