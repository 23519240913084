<template>
  <div style="position: absolute; left: 0; top: 0; height: 100%; width: 100%; display: flex">
    <v-card style="margin: 0 auto; flex-grow: 1; background-color: #e0e0e0">
      <v-card-text style="margin-top: 10em">
        <section id="firebaseui-auth-container"></section>
        <div class="card horizontal" style="max-width: 400px; margin: 0 auto" v-if="user">
          <div class="card-image" style="margin-top: 25px; margin-left: 10px">
            <img :src="user.photoURL" style="width: 75px; height: 75px; border-radius: 50%; border: 4px solid #333" />
          </div>
          <div class="card-stacked">
            <div class="card-content">
              <p>
                name:
                <strong>{{ user.displayName }}</strong>
                <br />email:
                <strong>{{ user.email }}</strong>
                <br />uid:
                <strong>{{ user.uid }}</strong>
                <br />provider:
                <strong class="teal-text">{{ user.providerData[0].providerId }}</strong>
              </p>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "../lib/firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

var auth = firebase.auth();
// auth.useEmulator("http://localhost:9099");

export default {
  name: "Login",
  props: {
    source: String
  },
  mounted() {
    auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(auth);
    }
    var uiConfig = {
      signInSuccessUrl: "#/",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
      ]
    };
    // ui.start("#firebaseui-auth-container", uiConfig);
    // Is there an email link sign-in?
    if (ui.isPendingRedirect()) {
      ui.start("#firebaseui-auth-container", uiConfig);
    } else if (auth.isSignInWithEmailLink(window.location.href)) {
      ui.start("#firebaseui-auth-container", uiConfig);
    } else {
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  },
  created() {
    this.$store.navigation.visible = false;
    auth.onAuthStateChanged(async user => {
      if (user) {
        try {
          await user.getIdToken();
          this.$store.user = user;
          this.$store.navigation.visible = true;
        } catch (e) {
          console.log(e);
        }
      }
    });
  },
  data: () => ({
    user: null
  }),
  methods: {
    signOut(e) {
      e.stopPropagation();
      auth.signOut();
    }
  }
};
</script>