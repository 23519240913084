import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import DatetimePicker from 'vuetify-datetime-picker'

Vue.config.productionTip = false
Vue.prototype.$store = store;
Vue.use(DatetimePicker)

const start = () => {
  new Vue({
    vuetify,
    router,
    render: h => h(App),
    theme: {
      light: true,
      themes: {
        light: {
          primary: '#784f2b',
          secondary: '#ffeb3b',
          accent: '#3f51b5',
          error: '#673ab7',
          warning: '#9c27b0',
          info: '#e91e63',
          success: '#4caf50',
        },
        dark: {
          primary: '#784f2b',
          secondary: '#ffeb3b',
          accent: '#3f51b5',
          error: '#673ab7',
          warning: '#9c27b0',
          info: '#e91e63',
          success: '#4caf50'
        },
      },
    },
  }).$mount('#app');
}

/* eslint no-prototype-builtins: "off" */
if (window.hasOwnProperty("cordova")) {
  document.addEventListener('deviceready', start, false);
} else {
  start();
}