import Vue from 'vue'
import VueRouter from 'vue-router'
import Device from '../views/Device.vue'
import Login from '../views/Login.vue'
// import Store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Device',
    component: Device
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/unlock',
    name: 'Unlock',
    component: () => import(/* webpackChunkName: "device" */ '../views/Unlock.vue')
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import(/* webpackChunkName: "orgs" */ '../views/Organizations.vue')
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import(/* webpackChunkName: "grps" */ '../views/Groups.vue')
  },
  {
    path: '/credits',
    name: 'Credits',
    component: () => import(/* webpackChunkName: "credits" */ '../views/Credits.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/Scan.vue')
  },
  {
    path: '/monitor/:id',
    name: 'Monitor',
    component: () => import(/* webpackChunkName: "monitor" */ '../views/Monitor.vue')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '../views/Calendar.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { showMenu: true, },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import(/* webpackChunkName: "log" */ '../views/Log.vue')
  },
  {
    path: '/people',
    name: 'People',
    component: () => import(/* webpackChunkName: "people" */ '../views/People.vue')
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue')
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  // if (to.name != 'Scan' && Store.device == undefined) {
  //   next({ name: 'Scan' })
  // }

  // if (to.name !== 'Login' && store.user == null) {
  //   next({ name: 'Login' });
  // } else {
  next();
  // }
})

export default router
