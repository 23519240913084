import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";

let firebaseConfig = {
    apiKey: "AIzaSyBvethscUJhAI9LRg6Wlj11xsMCjdL3DKE",
    authDomain: "armadillolock.com",
    databaseURL: "https://armadillo-5653e.firebaseio.com",
    projectId: "armadillo-5653e",
    storageBucket: "armadillo-5653e.appspot.com",
    messagingSenderId: "513875276964",
    appId: "1:513875276964:web:9813fb31c83468ba34a231",
    measurementId: "G-WNVGNMGBVN"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// if (location.hostname === "localhost") {
//     firebase.functions().useEmulator("localhost", 5001);
// }

export default firebase;